<template>
    <v-app>
        <div v-if="$auth.ready()">
            <router-view/>
        </div>
    </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  })
}
</script>
