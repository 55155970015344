import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'

const auth = {
  auth: bearer,
  http: axios,
  router: router,
  tokenDefaultName: 'laravel-jwt-auth',
  tokenStore: ['localStorage'],
  authRedirect: {
    path: '/login'
  },
  forbiddenRedirect: null,
  notFoundRedirect: {
    path: '/dashboard/account'
  },

  // API endpoints
  registerData: {
    url: 'register',
    method: 'POST',
    redirect: null
  },
  loginData: {
    url: 'login',
    method: 'POST',
    redirect: null,
    fetchUser: true
  },
  logoutData: {
    url: 'logout',
    method: 'POST',
    redirect: null,
    makeRequest: true
  },
  fetchData: {
    url: 'user',
    method: 'GET',
    enabled: true
  },
  refreshData: {
    url: 'refresh',
    method: 'POST',
    enabled: true,
    interval: 30
  }
}

export default auth
