<template>
    <v-app>
        <header-frame/>

        <v-main>

            <v-container
                fluid
                class="pa-8 pb-0"
            >
                <v-layout>
                    <v-flex>
                        <v-card
                            class="mx-auto"
                            max-width="406"
                            flat
                        >
                            <v-img src="storage/asa_logo.png" contain></v-img>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-4 pb-0"
            >
                <v-layout>
                    <v-flex>
                        <v-card
                            class="mx-auto"
                            max-width="1216"
                            flat
                        >
                            <v-card-title class="justify-center text-center display-1 text-break px-0">Advanced
                                Semiconductor Analysis Software
                            </v-card-title>
                            <v-card-subtitle class="text-center mt-0 title text-break px-0">…to simulate solar cells
                                quickly. The software uses an integrated opto-electrical approach, ideal for
                                optimization of a wide range of solar cells.
                            </v-card-subtitle>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-2"

            >
                <v-layout>
                    <v-sheet
                        class="mx-auto"
                        width="1216"
                    >
                        <v-layout class="flex-wrap">
                            <v-flex class="pa-2 flex-wrapped">
                                <v-card flat>
                                    <v-card-title class="justify-center text-center pa-0 pb-2">ASA7 features
                                    </v-card-title>
                                    <v-card-text class="pa-0">
                                        <ul>
                                            <li>Web interface: Create a user account and start using ASA immediately.
                                            </li>
                                            <li>Ideal optimization tool for thin-film, c-Si wafer based and tandem solar
                                                cells.
                                            </li>
                                            <li>GenPro (GP) optical model: for flat interfaces (GP1), light scattering
                                                at textured interfaces (GP2), with coherent treatment (GP3), combining
                                                ray and wave optics (GP4).
                                            </li>
                                            <li>Electrical model: solves semiconductor equations, includes tunneling
                                                models, to calculate band alignment and charge transport in wafer-based,
                                                thin-film and multi-junction solar cells.
                                            </li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                            <v-flex class="pa-2 text-center flex-wrapped">
                                <v-img src="storage/diagram.png" contain class="image"></v-img>
                                <span class="caption">Perovskite / silicon tandem solar cell. An example of what can be simulated using ASA.</span>
                            </v-flex>
                        </v-layout>
                    </v-sheet>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-4"
            >
                <v-layout>
                    <v-flex>
                        <v-card
                            class="mx-auto"
                            width="1216"
                            flat
                        >
                            <v-card-title class="justify-center text-center text-break pa-0 pb-4">Getting started
                            </v-card-title>
                            <v-card-subtitle class="text-center text-break px-0">Follow these steps to start your next
                                simulation with ASA within minutes!
                            </v-card-subtitle>
                            <v-stepper vertical class="py-0">
                                <v-stepper-step step="1">
                                    Register your account to activate your free two week trial.
                                </v-stepper-step>
                                <v-stepper-content step="1"></v-stepper-content>
                                <v-stepper-step step="2" class="v-stepper__step--active stepper">
                                    Start your first simulations and get used to the functionality ASA has to offer.
                                </v-stepper-step>
                                <v-stepper-content step="2"></v-stepper-content>
                                <v-stepper-step step="3" class="v-stepper__step--active stepper">
                                    Once you want to upgrade your account, or after the two weeks, visit the shop below
                                    to purchase a license!
                                </v-stepper-step>
                            </v-stepper>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-4"
            >
                <v-layout>
                    <v-flex>
                        <v-card
                            class="mx-auto"
                            width="1216"
                            flat
                        >
                            <v-card-title class="justify-center text-center text-break pa-0 pb-4">Interface demo
                            </v-card-title>
                            <v-card-subtitle class="text-center text-break px-0">Watch this video and see the advanced
                                features of ASA!
                            </v-card-subtitle>
                            <v-card-text class="pa-0">
                                <video class="video" controls>
                                    <source src="storage/demo.mp4" type="video/mp4">
                                </video>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-4 pb-2"
            >
                <v-layout>
                    <v-flex class="title text-center">
                        Choose your license!
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="px-2 py-0"
            >
                <v-layout
                    align-center
                    justify-center
                    class="flex-wrap"
                >
                    <!--                    <v-flex class="pa-2 license-container">-->
                    <!--                        <v-card>-->
                    <!--                            <v-toolbar flat>-->
                    <!--                                <v-spacer/>-->
                    <!--                                <v-toolbar-title>Trial</v-toolbar-title>-->
                    <!--                                <v-spacer/>-->
                    <!--                            </v-toolbar>-->

                    <!--                            <v-card-text class="pt-0">-->
                    <!--                                <div class="pb-4 price">-->
                    <!--                                    <span class="title pr-2 original-price">€{{ calculatePrice('trial', false) }}</span>-->
                    <!--                                    <span class="overline discount">SAVE {{ trial.discount * 100 }}%</span>-->
                    <!--                                </div>-->

                    <!--                                <div class="pb-8 price">-->
                    <!--                                    <span class="display-1 primary&#45;&#45;text">€</span>-->
                    <!--                                    <span class="display-2 primary&#45;&#45;text">{{ calculatePrice('trial', true) }}</span>-->
                    <!--                                </div>-->

                    <!--                                <v-btn-->
                    <!--                                    large-->
                    <!--                                    color="primary"-->
                    <!--                                    block-->
                    <!--                                    href="https://asa.mall.tudelft.nl/catalogue/category/asa-licenses_1/"-->
                    <!--                                    target="_blank"-->
                    <!--                                >-->
                    <!--                                    Add to cart-->
                    <!--                                </v-btn>-->

                    <!--                                <v-select-->
                    <!--                                    v-model="trial.option"-->
                    <!--                                    :items="trial.options"-->
                    <!--                                    return-object-->
                    <!--                                    label="Options"-->
                    <!--                                    hide-details-->
                    <!--                                    class="pt-6"-->
                    <!--                                />-->

                    <!--                                <v-list-->
                    <!--                                    flat-->
                    <!--                                    disabled-->
                    <!--                                    class="pb-0"-->
                    <!--                                >-->
                    <!--                                    <v-list-item-group multiple>-->
                    <!--                                        <v-list-item-->
                    <!--                                            v-for="(item, i) in trial.features"-->
                    <!--                                            :key="i"-->
                    <!--                                        >-->
                    <!--                                            <v-list-item-icon>-->
                    <!--                                                <v-icon :color="item.color">{{ item.icon }}</v-icon>-->
                    <!--                                            </v-list-item-icon>-->
                    <!--                                            <v-list-item-content>-->
                    <!--                                                <v-list-item-title>{{ item.description }}</v-list-item-title>-->
                    <!--                                            </v-list-item-content>-->
                    <!--                                        </v-list-item>-->
                    <!--                                    </v-list-item-group>-->
                    <!--                                </v-list>-->
                    <!--                            </v-card-text>-->
                    <!--                        </v-card>-->
                    <!--                    </v-flex>-->
                    <v-flex class="pa-2 license-container">
                        <v-card>
                            <v-toolbar flat>
                                <v-spacer/>
                                <v-toolbar-title>Month</v-toolbar-title>
                                <v-spacer/>
                            </v-toolbar>

                            <v-card-text class="pt-0">
                                <div class="pb-4 price">
                                    <span class="title pr-2 original-price">€{{ calculatePrice('month', false) }}</span>
                                    <span class="overline discount">SAVE {{ month.discount * 100 }}%</span>
                                </div>

                                <div class="pb-8 price">
                                    <span class="display-1 primary--text">€</span>
                                    <span class="display-2 primary--text">{{ calculatePrice('month', true) }}</span>
                                </div>

                                <v-btn
                                    large
                                    color="primary"
                                    block
                                    :href="getLink('month')"
                                    target="_blank"
                                >
                                    Add to cart
                                </v-btn>

                                <v-select
                                    v-model="month.option"
                                    :items="month.options"
                                    return-object
                                    label="Options"
                                    hide-details
                                    class="pt-6"
                                />

                                <v-list
                                    flat
                                    disabled
                                    class="pb-0"
                                >
                                    <v-list-item-group multiple>
                                        <v-list-item
                                            v-for="(item, i) in month.features"
                                            :key="i"
                                        >
                                            <v-list-item-icon>
                                                <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex class="pa-2 license-container">
                        <v-card>
                            <v-toolbar flat>
                                <v-spacer/>
                                <v-toolbar-title>Year</v-toolbar-title>
                                <v-spacer/>
                            </v-toolbar>

                            <v-card-text class="pt-0">
                                <div class="pb-4 price">
                                    <span class="title pr-2 original-price">€{{ calculatePrice('year', false) }}</span>
                                    <span class="overline discount">SAVE {{ year.discount * 100 }}%</span>
                                </div>

                                <div class="pb-8 price">
                                    <span class="display-1 primary--text">€</span>
                                    <span class="display-2 primary--text">{{ calculatePrice('year', true) }}</span>
                                </div>

                                <v-btn
                                    large
                                    color="primary"
                                    block
                                    :href="getLink('year')"
                                    target="_blank"
                                >
                                    Add to cart
                                </v-btn>

                                <v-select
                                    v-model="year.option"
                                    :items="year.options"
                                    return-object
                                    label="Options"
                                    hide-details
                                    class="pt-6"
                                />

                                <v-list
                                    flat
                                    disabled
                                    class="pb-0"
                                >
                                    <v-list-item-group multiple>
                                        <v-list-item
                                            v-for="(item, i) in year.features"
                                            :key="i"
                                        >
                                            <v-list-item-icon>
                                                <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex class="pa-2 license-container">
                        <v-card>
                            <v-toolbar flat>
                                <v-spacer/>
                                <v-toolbar-title>Unlimited</v-toolbar-title>
                                <v-spacer/>
                            </v-toolbar>

                            <v-card-text class="pt-0">
                                <div class="pb-4 price">
                                    <span
                                        class="title pr-2 original-price">€{{
                                            calculatePrice('unlimited', false)
                                        }}</span>
                                    <span class="overline discount">SAVE {{ unlimited.discount * 100 }}%</span>
                                </div>

                                <div class="pb-8 price">
                                    <span class="display-1 primary--text">€</span>
                                    <span class="display-2 primary--text">{{ calculatePrice('unlimited', true) }}</span>
                                </div>

                                <v-btn
                                    large
                                    color="primary"
                                    block
                                    :href="getLink('unlimited')"
                                    target="_blank"
                                >
                                    Add to cart
                                </v-btn>

                                <v-select
                                    v-model="unlimited.option"
                                    :items="unlimited.options"
                                    return-object
                                    label="Options"
                                    hide-details
                                    class="pt-6"
                                />

                                <v-list
                                    flat
                                    disabled
                                    class="pb-0"
                                >
                                    <v-list-item-group multiple>
                                        <v-list-item
                                            v-for="(item, i) in unlimited.features"
                                            :key="i"
                                        >
                                            <v-list-item-icon>
                                                <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container
                fluid
                class="pa-4 pt-2"
            >
                <v-layout>
                    <v-flex class="display-2 layout-title flex-center">
                        <v-switch
                            v-model="educationalPricing"
                            :label="'Educational discount (' + educationalDiscount * 100 + '%)'"
                            color="primary"
                            hide-details
                            class="mt-0 pt-0"
                        ></v-switch>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>

        <footer-frame/>
    </v-app>
</template>

<script>
import HeaderFrame from '@/js/components/HeaderFrame'
import FooterFrame from '@/js/components/FooterFrame'

export default {
  name: 'Home',
  components: {
    HeaderFrame,
    FooterFrame
  },
  mounted () {
    this.month.option = this.month.options[0]
    this.year.option = this.year.options[0]
    this.unlimited.option = this.unlimited.options[0]
  },
  data: () => ({
    educationalPricing: false,
    educationalDiscount: 0.2,
    month: {
      discount: 0.1,
      option: {
        text: '',
        value: 0,
        link: ''
      },
      options: [
        {
          text: 'GP1-GP4',
          value: 175,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp4_15/'
        },
        {
          text: 'GP1-GP3',
          value: 100,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp3_14/'
        }
      ],
      features: [
        {
          icon: 'mdi-calendar-clock',
          description: 'Valid 1 month',
          color: ''
        },
        {
          icon: 'mdi-check',
          description: 'Multi-junctions',
          color: 'success'
        }
      ]
    },
    year: {
      discount: 0.2,
      option: {
        text: '',
        value: 0,
        link: ''
      },
      options: [
        {
          text: 'GP1-GP4',
          value: 2100,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp4_17/'
        },
        {
          text: 'GP1-GP3',
          value: 1200,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp3_16/'
        }
      ],
      features: [
        {
          icon: 'mdi-calendar-clock',
          description: 'Valid 1 year',
          color: ''
        },
        {
          icon: 'mdi-check',
          description: 'Multi-junctions',
          color: 'success'
        }
      ]
    },
    unlimited: {
      discount: 0.3,
      option: {
        text: '',
        value: 0,
        link: ''
      },
      options: [
        {
          text: 'GP1-GP4',
          value: 12350,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp4_19/'
        },
        {
          text: 'GP1-GP3',
          value: 6950,
          link: 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp3_18/'
        }
      ],
      features: [
        {
          icon: 'mdi-calendar-clock',
          description: 'Valid unlimited time',
          color: ''
        },
        {
          icon: 'mdi-check',
          description: 'Multi-junctions',
          color: 'success'
        }
      ]
    }
  }),
  methods: {
    calculatePrice (type, discount) {
      let price = this[type].option.value
      if (this.educationalPricing) {
        if (type === 'trial' && !discount) {
          price = 5
        }
        price *= (1 - this.educationalDiscount)
      }
      if (!discount) {
        if (type === 'trial' && !this.educationalPricing) {
          price = 5
        }
        price *= (1 + this[type].discount)
      }
      return price.toFixed(0)
    },
    getLink (type) {
      if (type === 'month') {
        if (this[type].option.text === 'GP1-GP3') {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp3-educational-discount_21/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp3_14/'
          }
        } else {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp4-educational-discount_20/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-month-license-gp1-gp4_15/'
          }
        }
      } else if (type === 'year') {
        if (this[type].option.text === 'GP1-GP3') {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp3-educational-discount_22/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp3_16/'
          }
        } else {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp4-educational-discount_23/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-year-license-gp1-gp4_17/'
          }
        }
      } else if (type === 'unlimited') {
        if (this[type].option.text === 'GP1-GP3') {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp3-educational-discount_24/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp3_18/'
          }
        } else {
          if (this.educationalPricing) {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp4-educational-discount_25/'
          } else {
            return 'https://asa.mall.tudelft.nl/catalogue/asa-unlimited-license-gp1-gp4_19/'
          }
        }
      }
      return 'https://asa.mall.tudelft.nl/catalogue/category/asa-licenses_1/'
    }
  }
}
</script>

<style>
html {
    overflow-y : auto !important;
    overflow-x : hidden !important;
}

body {
    overflow-y : auto !important;
    overflow-x : hidden !important;
}

.link {
    color           : inherit !important;
    text-decoration : inherit !important;
    transition      : .3s cubic-bezier(.25, .8, .5, 1) !important;
}

.link:hover {
    color : #1976d2 !important;
}

.form-container {
    max-width : 500px !important;
    margin    : auto !important;
}

.flex-wrapped {
    flex-basis   : 50% !important;
    margin-left  : auto !important;
    margin-right : auto !important;
}

.flex-half {
    width : 50% !important;
}

.image /deep/ .v-responsive__content {
    width : auto !important;
}

tbody .v-data-table__mobile-table-row:nth-child(even) td {
    background-color : #eee !important;
}
</style>

<style scoped>
.video {
    width  : 100% !important;
    border : 6px solid rgba(0, 0, 0, .87) !important
}

.layout-title {
    text-align : center !important;
}

.flex-center {
    display         : flex !important;
    justify-content : center !important;
}

.stepper /deep/ .v-stepper__step__step {
    background-color : #1976d2 !important;
}

.license-container {
    max-width : 300px !important;
}

.price {
    text-align : center !important;
}

.price /deep/ button {
    pointer-events : none !important;
}

.original-price {
    text-decoration : line-through !important;
}

.discount {
    font-size : .75rem !important;
}
</style>
