import './bootstrap'
import Vue from 'vue'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import 'es6-promise/auto'
import axios from 'axios'
import auth from '@/js/plugins/auth'
import vuetify from '@/js/plugins/vuetify'
import router from '@/js/router/router'
import App from '@/js/views/App'

Vue.config.productionTip = false

window.Vue = Vue

Vue.router = router
Vue.use(VueRouter)

Vue.use(VueAxios, axios)
axios.defaults.baseURL = '/api'
// axios.interceptors.request.use(config => {
//   return config
// }, error => {
//   return Promise.reject(error)
// })
Vue.use(VueAuth, auth)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
